.main {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  display: flex;

  align-items: center;
  flex-direction: row;
}

.catchMe {
  position: absolute;
  font-weight: 600;
  top: 0;
  left: 0;
  transform: rotate(-15deg);
  color: var(--primary-purple);
  border: 1px solid var(--primary-purple);
  border-radius: 5px;
  padding: 0.1rem 0.5rem;
}

.text {
  height: auto;
  font-size: 32px;
  font-weight: 600;
  width: 100%;
}

.imgContainer {
  width: 1000px;
  height: 200px;
  position: relative;
}

.img1 {
  width: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
  animation: Ring1 14s ease-in-out infinite;
}
.img2 {
  position: absolute;
  width: 120px;

  animation: Ring2 14s ease-in-out infinite;
}
.img3 {
  position: absolute;
  width: 90px;
  top: 0;
  left: 0;
  animation: Ring3 14s ease-in-out infinite;
}

.img4 {
  position: absolute;
  width: 120px;
  bottom: 0;
  left: 0;
  animation: Ring4 14s ease-in-out infinite;
}

.img1Smaller {
  width: 90px;
  height: 90px;

  position: absolute;
  bottom: 0;
  right: 0;
  animation: Ring1 6s ease-in-out infinite;
}
.img2Smaller {
  position: absolute;
  width: 50px;
  height: 50px;

  animation: Ring2 6s ease-in-out infinite;
}
.img3Smaller {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 0;
  left: 0;
  animation: Ring3 6s ease-in-out infinite;
}

.img4Smaller {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  bottom: 0;
  animation: Ring4 6s ease-in-out infinite;
}

@keyframes Ring1 {
  0%,
  100% {
    transform: translateX(0) rotate(-20deg);
  }
  50% {
    transform: translateX(-250px) rotate(12deg);
  }
}

@keyframes Ring2 {
  0%,
  100% {
    transform: translateY(100px) rotate(-20deg);
  }
  50% {
    transform: translateY(15px) rotate(12deg);
  }
}

@keyframes Ring3 {
  0%,
  100% {
    transform: translateX(100px) rotate(-20deg);
  }
  50% {
    transform: translateX(200px) rotate(20deg);
  }
}

@keyframes Ring4 {
  0%,
  100% {
    transform: translateX(350px) translateY(-50px) rotate(20deg);
  }
  50% {
    transform: translateX(0px) translateY(40px) rotate(-20deg);
  }
}

@media (max-width: 600px) {
  .img4Smaller {
    display: none;
  }
  .container {
    padding: 0rem;
  }
  .imgContainer {
    overflow: hidden;
    width: 300px;
  }

  .text {
    font-size: 25px;
    text-align: center;
  }

  @keyframes Ring1 {
    0%,
    100% {
      transform: translateX(-10px) translateY(20px) rotate(-20deg);
    }
    50% {
      transform: translateX(120px) rotate(12deg);
    }
  }

  @keyframes Ring2 {
    0%,
    100% {
      transform: translateY(0) rotate(-20deg);
    }
    50% {
      transform: translateY(100px) translateX(20px) rotate(12deg);
    }
  }

  @keyframes Ring3 {
    0%,
    100% {
      transform: translateX(200px) rotate(-20deg);
    }
    50% {
      transform: translateX(30px) translateY(10px) rotate(20deg);
    }
  }
  @keyframes Ring4 {
    0%,
    100% {
      transform: translateX(-100px) translateY(120px) rotate(20deg);
    }
    50% {
      transform: translateX(-80px) translateY(110px) rotate(-20deg);
    }
  }
}

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.paginationButton {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 31px;
  width: 32px;
  color: var(--text-main);
  font-size: 13px;
  cursor: pointer;
  position: relative; /* Add position relative to the button */
}
.renderPageButtons {
  list-style: none;
  max-width: 100%;
  overflow: auto;

  display: flex;
  padding: 0;
  margin: 0;
  column-gap: 0.5rem;
}
.paginationButton1 {
  border: 1px solid var(--primary-border);
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.paginationButton1:hover {
  border: 1px solid var(--text-main);

  transition: 0.2s ease-in-out;
}

/* Add a pseudo-element for the border on hover */
.paginationButton::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  width: 0; /* Initial width, make it 0 to make the border grow from center */
  background-color: var(--text-main);
  z-index: 4;
  transition: width 0.3s, left 0.3s; /* Transition for width and left properties */
  transform: scaleX(0); /* Set initial scale to 0 to be invisible at first */
  transform-origin: center; /* Set the transform origin to the center */
}

/* Add a pseudo-element for the border on active (selected) items */
.paginationButton.activePage::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  width: 100%; /* Full width for active items */
  background-color: var(--text-main);
  z-index: 4;
  transition: transform 0.3s, width 0.3s, left 0.3s; /* Add transition for transform and width */
  transform: scaleX(1); /* Scale to full size on active items */
  transform-origin: center; /* Set the transform origin to the center */
}

.paginationButton:hover::before,
.paginationButton.activePage:hover::before {
  width: 100%; /* Full width on hover for both regular and active items */
  left: 0; /* Adjust left to start from the beginning */
  transform: scaleX(1); /* Scale to full size on hover */
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.paginationButton:disabled {
  cursor: default;
  color: var(--text-main);
}

.paginationPages {
  flex-grow: 0;
}

.scrollContainer {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
}
.scrollContainer::-webkit-scrollbar {
  height: 5px;
}

.scrollContainer::-webkit-scrollbar-thumb {
  cursor: default;
}

.scrollContainer::-webkit-scrollbar-track {
  cursor: default;
}

.activePage {
  font-weight: 500;
}

.title {
  font-size: 40px;
  /* line-height: 45px; */
  letter-spacing: -0.5px;
  margin-bottom: 2.5rem;
  font-weight: 500;
}

.faqBox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.titleP {
  font-size: 20px;
  /* line-height: 45px; */
  font-family: "Editorial";

  font-weight: 500;
}

.subTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: #222222;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0rem;
}

.subTitleP {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: #222222;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0rem;
}

.box {
  border-bottom: 1px solid #c5c8d3;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.boxFinal {
  padding-top: 1em;
  padding-bottom: 1rem;
}

.muteText {
  padding-top: 8px;
  font-size: 16px;
  color: #222222;
  margin-bottom: 0rem;
  font-weight: 300;
}

.muteTextP {
  padding-top: 8px;
  font-size: 14px;
  color: #222222;
  margin-bottom: 0rem;
  font-weight: 300;
}

.icon {
  margin-right: 0.5rem;
}

@media (max-width: 700px) {
  .title {
    font-size: 26px;
    margin-bottom: 1rem;
  }

  .titleP {
    font-size: 18px;
  }

  .subTitle {
    color: var(--primary-purple);
  }

  .muteText {
    font-weight: lighter;
  }
}

.main {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 0rem;
  column-gap: 1.5rem;
  flex-direction: row;
  max-height: 205px;
}

.leftSection {
  width: 25%;
}

.rightSection {
  width: 75%;
  max-width: 75%;
  display: grid;
  gap: 0.75rem;
  height: 100%;
  min-height: 187px;
  overflow-x: scroll;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  white-space: nowrap; /* Prevent wrapping of items */
}

.headerBox {
  width: 90%;
  min-height: 120px;
}

.header {
  font-size: 20px;
  color: var(--text-main);
  text-transform: uppercase;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 5px;
}

.descriptiveText {
  font-size: 14px;
  font-weight: 400;
  font-family: "Editorial";
  margin-bottom: 0rem;
  color: var(--text-mute);
}

.subHeader {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-main);
  text-transform: uppercase;
  margin-bottom: 0.6rem;
}

.color {
  font-family: "Editorial";
  color: var(--text-main);
  text-transform: uppercase;
  transition: linear 1s;
  font-size: 22px;
  margin-bottom: 0rem;
  font-weight: 500;
}

.mute {
  font-size: 13px;
  color: var(--text-body);
  font-weight: 300;
  line-height: 150%;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
}

.item {
  width: 100%; /* Add a fixed width */

  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  row-gap: 0.5rem;
  height: inherit;
  background-color: white;
}

.loadingBox {
  width: 100%;
  border-radius: 9999px;
  display: flex;
  aspect-ratio: 1/1;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-bg);
}
.loadingPlaceholder {
  width: 50%;
  aspect-ratio: 1/1;
  border-radius: 9999px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    /* Lightest shade, almost white but still has contrast */ hsl(0, 0%, 80%)
      50%,
    /* Medium shade for visible contrast */ hsl(0, 0%, 85%) 100%
      /* Light shade that's between the lightest and medium */
  );
  animation: anim 2s linear infinite;
}

.item:hover .imgContainer {
  filter: brightness(0.95);
  transition: 0.1s linear;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  max-height: 131px;
  border-radius: 9999px;
  padding: 1rem;
  aspect-ratio: 1/1;

  overflow: hidden;
  background-color: var(--primary-bg);
}

.imgContainerWhite {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  max-height: 131px;
  border-radius: 9999px;
  padding: 1rem;
  aspect-ratio: 1/1;

  overflow: hidden;
  background-color: white;
  border: 1px solid var(--primary-border);
}

.imgContainerWhite:hover {
  filter: brightness(0.95);
  transition: 0.1s linear;
}

.rightSectionBrand {
  display: flex;
  justify-content: flex-end;
  width: 75%;
  margin-bottom: 1rem;
}
.imgContainerBrand {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-height: 120px;
  max-width: 300px;
  /* padding: 1rem; */
  aspect-ratio: 1/1;

  overflow: hidden;
  background-color: white;
}

.img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  filter: brightness(1);
}

.imgContain {
  max-width: 130%;
  height: auto;
  width: auto;
  /* width: 100%; */
  object-fit: cover;
  filter: brightness(1);
}

.imgBrand {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  filter: brightness(1);
}
/* .img:hover {
  cursor: pointer;
  filter: brightness(0.9);
  transition: 0.1s linear;
} */

.text {
  /* position: absolute; */
  color: var(--text-body);
  font-family: "Grotesk";
  /* text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.9); */
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  /* top: 50%; */
  font-size: 11px;
  font-weight: 400;
  white-space: pre-wrap;
  /* transform: translateY(-50%); */
  cursor: pointer;
}

.filterSectionMobile {
  display: none;
}

@media (max-width: 600px) {
  .main {
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
    height: auto;
    row-gap: 0.75rem;
    max-height: none;
  }

  .rightSectionBrand {
    display: none;
  }

  .rightSection {
    display: flex;
    max-width: 100%;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    column-gap: 0.85rem;
    min-height: 0;
  }

  .header {
    font-size: 20px;
  }

  .item {
    height: fit-content;
    min-height: 124px;
    display: flex;
    max-width: 80px;
    row-gap: 0.5rem;
    flex-direction: column;
  }

  .loadingBox {
    width: 80px;
    height: 80px;
    margin-bottom: 64px;
    border-radius: 9999px;
    display: flex;
    aspect-ratio: 1/1;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-bg);
  }
  .text {
    position: relative;
    color: var(--text-main);
    text-shadow: none;
    font-size: 12px;
    font-weight: 400;
    top: 0%;
    transform: translateY(0%);
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .imgContainer {
    padding: 0.2rem;
    width: 80px;
    max-width: 80px;
    min-width: 80px;
    border: 2px solid var(--primary-border);
  }

  .imgContainerWhite {
    padding: 0.2rem;
    width: 80px;
    max-width: 80px;
    min-width: 80px;
  }

  .img {
    width: 100%;

    border-radius: 9999px;
    filter: brightness(1);
  }

  .descriptiveText {
    display: none;
  }

  .headerBox {
    margin-right: 0rem;
    min-height: auto;
    width: 60%;
  }

  .leftSection {
    display: flex;
    width: 100%;
    min-height: 51.4px;
    flex-direction: row;
    justify-content: space-between;
  }

  .filterSectionMobile {
    display: flex;
    flex-direction: row;
    width: fit-content;
    max-width: 40%;

    justify-content: space-between;

    font-size: 14px;
    border: 1px solid var(--text-main);
    color: var(--text-main);
    font-family: "Editorial";
    font-weight: 400;
    margin-bottom: 0rem;
    white-space: nowrap;
    border-radius: 3px;
    padding: 10px 20px;
    width: -moz-fit-content;
    width: fit-content;
    transition: 0.1s ease-in;
    height: 40px;
  }

  .filterButton {
    height: 18px;
    width: 18px;
    margin-right: 0.5rem;
  }
}

@keyframes anim {
  0%,
  100% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-left: 1rem;
	padding-right: 1rem;
	color: #222222;
	font-weight: 500;
	height: 8%;
	align-items: center;

	font-size: 20px;
	border-bottom: 1px solid var(--primary-bg);
}

.footer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 5rem;
	color: var(--primary-purple);
	border-top: 1px solid var(--primary-bg);
	font-size: 16px;
	cursor: pointer;
	background-color: white;

	position: sticky;
	bottom: 0;
}

.closeIcon:hover {
	cursor: pointer;
}

.offCanvas {
	background-color: white;
	z-index: 3;
	position: fixed;
	top: 0px;
	right: 0;
	height: 100%;
	width: 390px;
	box-shadow: rgb(0 0 0 / 25%) 0px 10px 20px;
}

@media (max-width: 600px) {
	.offCanvas {
		width: 100%;
		top: 0;
		height: calc(100%);
	}
}

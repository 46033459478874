.main {
  max-width: var(--max-width);

  margin-left: auto;
  margin-right: auto;
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: 3.25rem;
  margin-bottom: 2rem;

  font-size: 32px;
  font-weight: 500;
}

.subTitle {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 2rem;
  color: black;
}

.choices {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--primary-bg);
}

.choice {
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  color: var(--text-body);
  height: 54px;
  min-width: 60px;
  padding: 1.25rem;
}

.choice:hover {
  cursor: pointer;
  color: var(--text-main);
  font-weight: 400;
}

.activeChoice {
  font-size: 16px;

  font-weight: 400;
  border-bottom: 1px solid black;
  color: var(--text-main);

  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  min-width: 60px;
  padding: 1.25rem;
  transition: 0.2s linear;
}

.cartContainer {
  padding-top: 2rem;
}

/* Favorites */

.alignCenter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 2.5em;
}

.listing {
  display: flex;
  width: 19%;
  margin-bottom: 1em;
  border-radius: 8px;
  background-color: white;
}

/* InfoBox */

.infoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.exploreButton {
  background-color: var(--primary-purple);
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 5px;
}

.link {
  text-decoration: none;
}

.exploreButton:hover {
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* Saved */

.savedMain {
  display: flex;
  flex-direction: row;
}
.leftSection {
  background-color: transparent;
  min-width: 15em;
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.rightSection {
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 2.5em;
}

.savedBox {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: white;
}

.savedBox:hover {
  background-color: #f5f5f6;

  cursor: pointer;
}

.savedBoxActive {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 0.2rem;
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: #f5f5f6;
}

.savedBoxActive:hover {
  cursor: pointer;
}

.topText {
  font-size: 16px;
  margin-bottom: 0px;
  color: var(--text-main);
  font-weight: 500;
}

.topTextActive {
  font-size: 16px;
  margin-bottom: 0px;
  color: var(--primary-purple);
  font-weight: 500;
}

.bottomText {
  font-size: 14px;
  margin-bottom: 0;
}

/* Else */

.justifyBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.delete {
  height: fit-content;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 2rem;
  color: var(--primary-purple);
}

.delete:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media (max-width: 800px) {
  .main {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .choices {
    justify-content: center;
  }

  .delete {
    padding-top: 0rem;
  }

  .main {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }

  .listing {
    min-width: 0;
    width: 48%;
  }

  .choice {
    font-size: 12px;
  }

  .activeChoice {
    font-size: 12px;
  }

  .subTitle {
    padding-left: 0.5rem;
  }

  .header {
    font-size: 28px;
    text-align: center;
  }

  .leftSection {
    min-width: 30%;
  }
}

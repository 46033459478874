.main {
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  margin-bottom: 2rem;
  row-gap: 3.5rem;
}

@media (max-width: 600px) {
  .main {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

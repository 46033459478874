.relatedItems {
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

.similarItems {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

.headerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.seeAll {
  background-color: transparent;
  font-size: 18px;
  margin-right: 12px;
  margin-bottom: 0rem;
  font-weight: 400;
  padding: 0rem;
  margin: 0rem;
  font-family: "Editorial";

  color: var(--text-body);
}

.seeAll:hover {
  color: var(--text-main);
  cursor: pointer;
}

.header {
  margin-left: 0rem;
  margin-right: auto;
  font-size: 20px;
  font-family: "Editorial";

  font-weight: 500;
  margin-bottom: 0rem;
}

.Link {
  text-decoration: none;
}

@media (max-width: 600px) {
  .header {
    margin-right: auto;
    font-size: 18px;
  }
  .seeAll {
    font-size: 16px;
    margin-bottom: 0px;
  }
}

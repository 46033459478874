.favDiv {
	display: flex;
	justify-content: center;
	align-items: center;
	height: inherit;
	width: inherit;
	min-width: inherit;
	border-radius: 2px;
	border: 1px solid var(--primary-border);
	transition: all 0.2s ease-in-out;
	background-color: rgba(255, 255, 255, 0.343);
	padding: 10%;
	gap: 0.5rem;
	z-index: 2;
}

.favoriteImage {
	width: 20px;
	height: 20px;
	border-radius: 100%;
	object-fit: cover;
}

.length {
	font-weight: 500;
	color: var(--text-body);
	margin-bottom: 0rem;
	margin-right: 3px;
}

.favoritePartOf {
	width: 100%;
	height: 100%;
	color: #ff1a49;
	z-index: 1;
}

.margin {
	margin-left: 0.2rem;
}

.favoritePartOf:hover {
	cursor: pointer;
	transform: scale(1.2);
	transform: rotate(8deg);
	transition: all 0.2s ease-in-out;
}

.favoritePartOf:active {
	animation: wobble 0.5s 1;
	transition: all 0.2s ease-in-out;
}
/* FavoriteIcon */

.favoriteIcon {
	width: 100%;
	height: 100%;
	color: #ff1a49;
	z-index: 1;
	transition: all 0.2s ease-in-out;
}

.favoriteIcon:hover {
	cursor: pointer;
	transform: rotate(8deg);
}

.favoriteIcon:active {
	animation: wobble 0.5s 1;
}

@media (max-width: 600px) {
}

.listingCard {
  width: 100%;
  background-color: var(--primary-bg);
  color: var(--text-main);
  text-decoration: none;
  border-radius: 2px;

  position: relative;
  /* padding: 0.5rem; */
}

.listingCard:hover {
  text-decoration: none;
  color: var(--text-main);
  -webkit-box-shadow: inset 0px 0px 0px 0.3px var(--primary-border);
  -moz-box-shadow: inset 0px 0px 0px 0.3px var(--primary-border);
  box-shadow: inset 0px 0px 0px 0.3px var(--primary-border);
}

.freeShippingBadge {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  top: 5px;
  left: 0.5rem;
  text-transform: uppercase;
  font-size: 11px;
  background-color: var(--priamry-red-400);
  font-weight: 400;
  z-index: 1;
  color: var(--text-main);
}

.newBadge {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  top: 5px;
  left: 0.5rem;
  text-transform: uppercase;
  font-size: 11px;
  background-color: #f5f2ee99;
  border: 1px solid #ecddc7;
  border-radius: 2px;
  font-weight: 400;
  z-index: 1;
  white-space: nowrap;
  color: var(--text-main);
}

.freeShipping {
  position: absolute;
  color: white;
  font-family: "Editorial";
  padding: 3px 5px;
  font-size: 10px;
  background-color: var(--tetradic-blue);
  top: 0.5rem;
  right: 0.5rem;
  border-radius: 2px;
}
.negotiable {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border: 0.5px solid var(--text-mute);
  color: var(--text-mute);
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-bottom: 0.1rem;
  padding-top: 0.1rem;
  width: fit-content;
  border-radius: 2px;
  max-height: 17px;
  margin-top: auto;
  margin-bottom: auto;
}

.listing {
  width: 31%;
  display: flex;
}

.quantity {
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 13px;
  color: var(--text-mute);
}

.dot {
  height: 5px;
  width: 5px;
  margin-right: 5px;

  background-color: var(--text-mute);
  border-radius: 100%;
}

.MessageCard {
  width: 100%;

  height: 15rem;
  border-radius: 8px !important;
  background-color: var(--primary-purple);
  /* padding: 0.5rem; */
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-bottom: 40px;
  flex-direction: column;
}

.viewedXTimes {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  height: 2em;
  font-size: 12px;
  top: 0.5em;
  right: 0.5em;
  margin: auto;
  color: #222222;
  font-weight: 300;
  text-shadow: 0.5px 0.5px 1px white;
}

.viewedIcon {
  margin-top: auto;
  margin-bottom: auto;
  height: 20px;
  margin-right: 0.2rem;
  width: 20px;
}

.brand {
  margin-bottom: 0rem;
  font-size: 13px;
  font-weight: 400;
  color: var(--text-main);
  opacity: 0.65;
}

.sold {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--tetradic-blue);
  text-align: center;
  color: white;
  font-weight: 600;
  opacity: 0.8;
  width: 100%;
  z-index: 1;
}

.dealBadge {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  height: 2em;
  bottom: 0.5rem;
  left: 0.5rem;
  margin: auto;

  border-radius: 0 2px 2px 0;
  padding: 6px 8px;
  color: white;
  /* background-color: var(--primary-red-400); */
  background-color: #ba4444;
  font-size: 11px;
  font-weight: 400;
}

.dealBadgePlaceholder {
  position: absolute;

  z-index: 1;
  height: 2em;
  width: 5rem;
  top: 0em;
  left: 0em;
  background-color: lightgrey;
  border-radius: 2px;
}

.messageCardText {
  margin-top: 30px;
  margin-bottom: 20px;
  color: white;
  font-size: 20px;
}

.messageCardIcon {
  height: 4em;
}

.Link {
  text-decoration: none;
  color: black;
}

.Link:hover {
  text-decoration: none;
}

.postDate {
  margin: 0%;
  padding: 0%;
  color: gray;
  font-size: 13px;
  font-style: italic;
}

.retailPrice {
  color: var(--text-main);
  text-decoration: line-through;
  font-size: 13px;
  letter-spacing: 0.02em;
  opacity: 0.65;
}

.price {
  color: var(--text-main);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.priceRed {
  color: var(--text-main);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.carousel {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
/* .carousel:hover {
  box-shadow: rgba(100, 100, 111, 0.1) 0px 0px 5px 0px;
} */

.carouselPlaceholder {
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(210, 27%, 96%) 0%,
    hsla(180, 3%, 78%, 0.433) 50%,
    hsl(210, 28%, 93%) 100%
  );
  animation: anim 2s infinite;
  position: relative;
  text-align: center;
  display: flex;
  height: 13.5rem;
  justify-content: center;
  overflow: hidden;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.placeholder {
  width: 100%;
  background-color: var(--primary-bg);
  height: 13.5em;
  border-radius: 5px;
}

.listingImg {
  height: 13.5em;
  width: inherit;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  filter: blur(15px);
  z-index: 1;
}

.soldImg {
  opacity: 0.5 !important;
  max-height: 13.5em;
  width: 100%;
  z-index: 1;
  transition: 0.2s ease;
  object-fit: cover;
}

.soldImg:hover {
  transform: scale(1.1);
  transition: 0.2s ease;
}

.listingImg1 {
  height: 160px;
  width: 160px;

  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  /* padding: 0.5rem; */
  padding-bottom: 0rem;
  margin-left: auto;
  margin-right: auto;
  transition: 0.2s ease;
  object-fit: contain;
}

.listingImg1:hover {
  transform: scale(1.1);
  transition: 0.2s ease;
}

.soldImg1 {
  height: 160px;
  width: 160px;

  margin-top: 1.5rem;
  z-index: 1;
  /* padding: 0.5rem; */
  padding-bottom: 0rem;
  margin-left: auto;
  margin-right: auto;
  transition: 0.2s ease;
  object-fit: scale-down;
}

.soldImg1:hover {
  transform: scale(1.1);
  transition: 0.2s ease;
}

/* Card Body */

.cardBody {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px;
  gap: 0.35rem;
}

.cardBodyPlaceholder {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 7em;
  max-height: 7em;
  /* padding-top: 0.8rem;
	padding-left: 0.2rem; */
  padding-top: 1rem;
  margin-bottom: 2rem;
  background-color: white;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.placeholderBadge {
  background-color: var(--primary-bg);
  width: 85%;
  height: 10px;
  margin-top: 1rem;
  border-radius: 8px;
  margin-right: auto;
  margin-left: auto;
}

.titleDiv {
  margin-top: auto;
  margin-bottom: 0rem;
}
.title {
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Editorial";
  font-weight: 400;
  letter-spacing: 0.025rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
  margin-bottom: 0rem;
}

.infoSection {
  margin-top: 4px !important;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.infoSection1 {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  gap: 5px;
}

/* FavoriteIcon */

.favorite {
  height: 25px;
  width: fit-content;
  max-width: 50px;
  position: absolute;
  right: 6px;
  bottom: 6px;
}

@media (max-width: 600px) {
  .messageCardText {
    font-size: 14px;
  }

  .title {
    font-size: 14px;
  }

  .brand {
    font-size: 12px;
  }
  .quantity {
    font-size: 12px;
  }

  .retailPrice {
    font-size: 11px;
  }
  /* .cardBody {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    padding-left: 0.2rem;
  } */

  .favoriteIcon {
    position: absolute;
    right: 5px;
    top: 10px;
    color: black;
    z-index: 2;
  }

  .favorite {
    width: fit-content;
  }

  .price {
    font-size: 14px;
  }

  .priceRed {
    font-size: 14px;
  }
}
@keyframes anim {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

@keyframes wobble {
  25% {
    transform: rotate(2deg);
    transform: scale(1.5);
  }
  50% {
    transform: rotate(-3deg);
  }
  75% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.bg {
  background-color: var(--primary-purple);

  height: 100vh;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;

  position: relative;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  height: inherit;
  width: inherit;
  column-gap: 5rem;
}

.leftSection {
  height: 500px;
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.rightSection {
  width: 50%;
  height: 500px;
}

.h1 {
  font-size: 120px;
  color: white;
  text-shadow: var(--text-shadow);
  margin-bottom: 0rem;
  font-weight: 500;
}

.h2 {
  font-size: 35px;
  font-weight: 500;
  color: white;
  text-shadow: var(--text-shadow);
}

.h3 {
  font-size: 18px;
  font-family: "Editorial";
  color: white;
  text-shadow: var(--text-shadow);
  margin-bottom: 3rem;
}

.link {
  color: white;
  text-shadow: var(--text-shadow);
}

.image {
  height: 100%;
}

@media (max-width: 600px) {
  .container {
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .leftSection {
    height: 30%;
    justify-content: center;
    width: 100%;
  }

  .rightSection {
    height: 70%;
    margin-top: 1.5rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .h1 {
    font-size: 80px;
  }

  .h2 {
    font-size: 25px;
  }
  .h3 {
    font-size: 15px;
    line-height: 180%;
  }
}

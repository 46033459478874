.hero {
  display: flex;
  flex-direction: row;
  background-color: var(--primary-bg);
}

.heroSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 1rem;

  width: 40%;
  row-gap: 2rem;
}

.heroSectionSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 1rem;

  width: 30%;
  row-gap: 2rem;
}

.title {
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.description {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0rem;
  line-height: 150%;
}
.heroImageSection {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.heroImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.aboutUsTitle {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0rem;
}

.rowGap1 {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.aboutUsText {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0rem;
  line-height: 150%;
  color: var(--text-body);
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  gap: 1rem;
  margin-top: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listing {
  background-color: var(--primary-bg);
}

.link {
  text-decoration: none;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.button {
  background-color: var(--text-main);
  color: white;
  width: 100%;
  padding: 15px 20px;
  border-radius: 2px;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.225rem;
  transition: all 0.3s ease;
}

.button:hover {
  opacity: 0.8;
  transition: all 0.3s ease;
}

/* FACTS */

.factsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  row-gap: 2rem;
}

.facts {
  display: flex;
  flex-direction: row;
}

.subTitle {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.rowGap05 {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.factKey {
  font-size: 14px;
  font-weight: 500;
  min-width: 105px;
  margin-bottom: 0rem;
  color: var(--text-main);
}

.fact {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0rem;
  color: var(--text-body);
}

.factsImgSection {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.factsImg {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.logo {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.textSection {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  text-align: center;
}

.colorBlack {
  color: var(--text-main);
}

.colorBlack:hover {
  opacity: 0.82;
}

@media (max-width: 600px) {
  .hero {
    display: flex;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
  }

  .heroSection {
    width: 100%;
  }

  .heroImageSection {
    width: 100%;
  }

  .heroImage {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .heroSectionSmall {
    width: 100%;
  }

  .button {
    max-width: 100%;
  }

  .facts {
    display: flex;
    flex-direction: column;
  }

  .factsImgSection {
    width: 100%;
  }

  .factsImg {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .textSection {
    margin-top: -3.5rem;
  }

  .productGrid {
    grid-template-columns: repeat(auto-fill, minmax(45%, 2fr));
  }
}

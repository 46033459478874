.searchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  /* background-color: transparent; */
  transition: all 0.1s ease-in-out;
  border-radius: 3px !important;
  border: 1px solid var(--primary-border);
  position: relative;
}

.searchPlaceholder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 3.5px;
  z-index: 0;
  cursor: text;
  color: var(--text-mute);
  position: absolute;
  left: 1rem;
}

.closeIcon {
  background-color: var(--primary-bg-dark);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  padding: 2px;
  transform: translateY(-50%);
  right: 10px;
  width: 20px;
  height: 20px;
  color: var(--text-body);
}

.searchBar::placeholder {
  color: var(--text-body);
}

.searchBar:hover {
  cursor: pointer;
  border: 1px solid var(--text-body);
}

.searchBar:focus {
  box-shadow: 0 0 5px var(--primary-purple);
}

.inputGroup {
  align-items: center !important;
}

.input {
  width: 100%;
  padding: 0;
  height: 100%;
  border: none;
}

.input:focus {
  outline: none !important;
  border: none !important;
}

.input:active {
  outline: none !important;
  border: none !important;
}

.relative {
  position: absolute;
  width: 100%;
  left: 0;
  top: 3rem;
}

.width {
  width: inherit;
  position: relative;
}

.searchDiscovery {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
}

.displayNone {
  position: absolute;
  cursor: auto !important;
  visibility: hidden !important;
  background-color: transparent;
}

.displayNone:hover {
  box-shadow: none !important;
  transform: translateY(0) !important;
}

/* .searchContainer:hover {
	transform: translateY(-1px);
} */

.lenseIcon {
  top: 50%;
  right: 5px;
  width: 15px;
  height: 15px;
  color: var(--text-body);
}

/*  */
